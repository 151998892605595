/* Common styles for Terms and Privacy pages */
.terms-page,
.privacy-page {
  overflow-x: hidden;
}

/* Header styles */
.container.text-center {
  padding: 0.5rem 1rem;
  background: white;
  position: sticky;
  top: 0;
  z-index: 1000;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.container.text-center>div {
  display: inline-block !important;
  margin: 0 10px;
}

.container.text-center a {
  text-decoration: none;
}

.container.text-center .btn-sm {
  padding: 0.25rem 0.5rem;
  line-height: 1.2;
}

@media (max-width: 768px) {
  .container.text-center {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    padding: 0.5rem;
    flex-direction: row !important;
  }

  .container.text-center>div {
    margin: 0 5px;
    font-size: 12px;
  }

  .container.text-center .btn-sm {
    padding: 0.2rem 0.4rem;
  }
}

/* Navigation pills */
.terms-pill a,
.privacy-pill a {
  font-size: 0.9rem;
  white-space: nowrap;
}

@media (max-width: 768px) {

  .d-flex.flex-wrap.gap-1,
  .d-flex.flex-wrap.gap-2 {
    justify-content: center;
  }

  .terms-pill a,
  .privacy-pill a {
    padding: 0.5rem 1rem !important;
    font-size: 0.8rem;
  }
}

/* Content sections */
.card-body {
  padding: 2rem !important;
}

@media (max-width: 768px) {
  .card-body {
    padding: 1rem !important;
  }

  h3 {
    font-size: 1.5rem;
  }

  h4 {
    font-size: 1.25rem;
  }

  .feature-icon-small {
    padding: 0.5rem !important;
  }

  .icon-box {
    width: 36px !important;
    height: 36px !important;
  }

  .fs-4 {
    font-size: 1.1rem !important;
  }
}

/* Icon box styles */
.icon-box {
  flex-shrink: 0 !important;
  width: 32px !important;
  height: 32px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  margin-right: 12px !important;
}

.d-flex.align-items-center {
  display: flex !important;
  align-items: flex-start !important;
}

.d-flex.align-items-center p {
  margin: 0;
  flex: 1;
  min-width: 0;
  word-wrap: break-word;
}

@media (max-width: 768px) {
  .icon-box {
    width: 28px !important;
    height: 28px !important;
    margin-right: 8px !important;
  }
  
  .icon-box i {
    font-size: 0.8rem;
  }
}

/* Gradient background */
.bg-gradient {
  position: relative;
}

@media (max-width: 768px) {
  .bg-gradient {
    padding: 4rem 0 6rem !important;
  }

  .container[style*="marginTop: '-13rem'"] {
    margin-top: -8rem !important;
  }
}

/* Contact section */
.btn-small {
  font-size: 0.9rem;
  padding: 0.5rem 1.5rem;
}

@media (max-width: 768px) {
  .btn-small {
    width: 100%;
    margin-top: 1rem;
  }
}

/* Utility classes */
.text-muted {
  color: #6c757d !important;
}

.bg-primary-soft {
  background-color: rgba(0, 97, 242, 0.1) !important;
}

.btn-soft-primary {
  background-color: rgba(0, 97, 242, 0.1);
  color: #0061f2;
  border: none;
  transition: all 0.3s ease;
}

.btn-soft-primary:hover {
  background-color: #0061f2;
  color: white;
}